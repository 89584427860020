<!--
data: 
- title: text
- subTitle: text
- text: richtext
- list: object (title: text, text: richtext, image: image, color: text, number: number, buttonLink: text)
- bgText: text
- backgroundImage: image
- style: text (light, dark, color)
-->
        
<template>
    <div class="cs-block cs-orbital-slider-block" :class="styleClass">
        <div class="cs-block-base">
            <div class="container">
                <h2 class="cs-title" v-if="data.title">{{ data.title }}</h2> 
                <h3 class="cs-sub-title" v-if="data.subTitle">{{data.subTitle}}</h3>
                <span class="cs-text" v-if="data.text" v-html="data.text"></span>

                <Slider :data="imageSlides" v-slot="item">

                    <div class="slide-card">
                        <CSButton
							v-if="item.slide.buttonLink"
							:data="item.slide.buttonLink"
						>

                            <div class="card-header">
                                <div
                                    class="card-chart"
                                    :style="`--p: ${item.slide.number}; --c: ${item.slide.color}`"
                                ></div>
                                <img
                                    class="card-image"
                                    :src="item.slide.image"
                                    :alt="item.slide.title"
                                />
                            </div>

                            <h4 v-if="item.slide.title || item.slide.number" class="title"><span v-if="item.slide.number">{{ item.slide.number }}</span> {{item.slide.title}}</h4>
                            <span v-if="item.slide.text" class="text cs-text" v-html="item.slide.text"></span>
                        </CSButton>
                    </div>

                </Slider>

            </div>
        </div>
    </div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
    name: 'OribitalSlider',
    components: { 
        Slider: () => import("../blocks_components/Slider.vue"),
        CSButton: () => import("../components/CSButton.vue"),
    },
    props: {
        data: {
            type: Object,
        default: () => {}
        }
    },
    data() {
        return {}
    },
    computed: computed('OrbitalSlider')
}
</script>

<style lang="scss" scoped>
@import '../styles/main.scss';

.cs-block.cs-orbital-slider-block{
    &.cs-style-dark {
        h4.title {
            color: #fff !important;
        }
        span.text {
            color: #fff !important;
            ::v-deep {
                color: #fff !important
            }
        }
    }

    text-align:center;

    .cs-text {
        display: block;
        margin-bottom: 30px;
    } 

    .cs-sub-title{
        font-weight: 400;
        padding: 10px 0;
        font-size: 20px;
    }

    .slider-item {
        width: 150px;

        .slide-card{
            ::v-deep .cs-button-container {
                cursor: pointer;
                display: block;
                position: relative;
                text-decoration: none;
                color: $secondary_text_color;
                height: 100%;
                .card-header {
                    position: relative;
                    width: 185px;
                    height: 185px;
                    margin: auto;
                    background-color: transparent;
                    border-radius: 0;
                    border: none;
                    padding: 0;
                    border-radius: 50%;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media (max-width: 500px) {
                        width: 240px;
                        height: 240px;
                    }
                    .card-chart {
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        --v: calc(((18 / 5) * var(--p) - 90) * 1deg);

                        width: 100%;
                        height: 100%;
                        display: inline-block;
                        border-radius: 50%;
                        padding: 5px;
                        background: linear-gradient(
                                    var(--v),
                                    $text_color_over_theme_color 50%,
                                    transparent 0
                                )
                                0 / min(100%, (50 - var(--p)) * 100%),
                            linear-gradient(var(--v), transparent 50%, var(--c) 0) 0 /
                                min(100%, (var(--p) - 50) * 100%),
                            linear-gradient(
                                to right,
                                $text_color_over_theme_color 50%,
                                var(--c) 0
                            );
                        -webkit-mask: linear-gradient($text_color_over_theme_color 0 0)
                                content-box,
                            linear-gradient($text_color_over_theme_color 0 0);
                        -webkit-mask-composite: destination-out;
                        mask-composite: exclude;
                    }
                    .card-image {
                        width: 70%;
                        height: 70%;
                        object-fit: contain;
                        position: relative;
                        z-index: 2;
                    }
                }
                h4.title {
                    @include small-title;
                    margin-top: 15px;
                    margin-bottom: 15px;
                    span {
                        font-weight: inherit;
                    }
                }
                span.text {
                    @include text;
                }
            }
        }
    }
}
</style>
